/*
Template Name:  Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/


.offcanvas-header .btn-close {
  display: none !important;
}


.offcanvas-600 {
  width: 600px !important;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.offcanvas-title {
  width: 100%;
}

.secondary {
  color: #f06548 !important;
}


.cursor {
  cursor: pointer;
}


.primary {
  color: #4780da;
}

.btn-primary {
  color: #fff !important;
  background-color: #4780da !important;
}

.btn-outline-primary {
  color: #4780da !important;
  --vz-btn-border-color: #4780da !important;

}

.btn-outline-primary:hover {

  color: #fff !important;
  background-color: #4780da !important;
  border-color: #4780da !important;
}

.btn-danger {
  color: #fff !important;
}

.text-primary {
  color: #4780da !important;
}

a {
  color: #4780da !important;
}

.navbar-menu .navbar-nav .nav-sm .nav-link.active {
  color: #4780da !important;
}

[data-layout="twocolumn"] .twocolumn-iconview .nav-icon.active .icon-dual {
  color: #4780da !important;
}

.popover {
  max-width: 400px;
  width: 400px;

  @include media-breakpoint-down(sm) {
    max-width: 300px;
    width: 300px;
  }

}

.table> :not(caption)>*>* {
  border-bottom-width: 0px !important;
}

.navbar-menu .navbar-nav .nav-sm .nav-link {
  padding: 0.55rem 1.2rem !important;
}

.form-check-input:checked{
  background-color: #4780da !important;
  border-color: #4780da !important;
}


.image-grid {
  display: flex;
  flex-wrap: wrap;
}

.thumbnail-container {
  position: relative;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
}

.selected-image {
  height: 80px;
  width: 80px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
  display: contents;
  padding: 10px;
  place-content: center;
  width: 100%;
  align-items: center;
}

.full-size-image {
  max-width: 80vw;
  max-height: 80vh;
}


.selected-images {
  height: 80px;
  width: 80px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
  // display: contents;
  place-content: center;
  width: 100%;
  align-items: center;
  padding: 10px;
}


.close-button {
  // left: 95px;
  // font-size: 20px;
  // border: none;
  // color: #f06548;

  // min-width: 24px;
  // border-radius: 50%;
  // position: absolute;
  // z-index: 0;
  // bottom: auto;
  // margin-right: 0;
  // top: -5px;
  //  cursor: pointer;



  left: 75%;
  top: 0px;
  font-size: 20px;
  border: none;
  color: #f06548;
  min-width: 24px;
  border-radius: 50%;
  position: absolute;
  z-index: 0;
  bottom: auto;
  margin-right: 0;

  cursor: pointer;
  background: white;
}

 
.iframe {
  width: 100% !important;
  height: 500px !important;

  @include media-breakpoint-down(sm) {
    width: 300px !important;
    height: 500px !important;
  }
}

.img {

  max-width: 100% !important;
  max-height: 100% !important;

}